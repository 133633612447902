import React from 'react'
import './Home.css'
import Header from '../Core/Header/Header';
import Footer from '../Core/Footer/Footer';
import Card1 from '../Assets/images/piping.jpeg';
import Card2 from '../Assets/images/instrumentation.jpeg';
import Card3 from '../Assets/images/labour.png';
import Customer from '../Core/OurTeamReview/Customer';
import Carousel from 'react-bootstrap/Carousel';
import Con1 from '../Assets/images/con1.jpg';
import Con2 from '../Assets/images/con2.jpg';
import Con3 from '../Assets/images/con4.jpg';
import Con4 from '../Assets/images/con5.jpg';
import Con5 from '../Assets/images/4th.jpeg';


function Home() {




    return (
        <div>

            <Header />


            <Carousel>
                <Carousel.Item>
                    <img
                        className="carousel-slide"
                        src={Con1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h1 className='carousel-header'>D.D.S.Y Construction</h1>
                        <h3 className='carousel-co-header'> " We are pro in Construction "</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="carousel-slide"
                        src={Con2}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                    <h1 className='carousel-header'>D.D.S.Y Construction</h1>
                        <h3 className='carousel-co-header'> " We are pro in Construction "</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                         className="carousel-slide"
                        src={Con3}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                    <h1 className='carousel-header'>D.D.S.Y Construction</h1>
                        <h3 className='carousel-co-header'> " We are pro in Construction "</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                         className="carousel-slide"
                        src={Con4}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                    <h1 className='carousel-header'>D.D.S.Y Construction</h1>
                        <h3 className='carousel-co-header'> " We are pro in Construction "</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                         className="carousel-slide"
                        src={Con5}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                    <h1 className='carousel-header'>D.D.S.Y Construction</h1>
                        <h3 className='carousel-co-header'> " We are pro in Construction "</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="carousel-slide"
                        src={Card3}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                    <h1 className='carousel-header'>D.D.S.Y Construction</h1>
                        <h3 className='carousel-co-header'> " We are pro in Construction "</h3>
                    </Carousel.Caption>
                </Carousel.Item>


            </Carousel>




            <section className="services">
                <div className="container">
                    <h1 className="services-heading-home">Services</h1>
                    <div className="flex-container">

                        <div className="card-home">
                            <div className="card-img-home">
                                <img src={Card1} alt="PlanningImage" />
                            </div>
                            <div className="card-text-home">
                                <h4 className="card-heading-home">Piping Fabrication and Erection under ground at work</h4>
                                <p className="card-body-home">
                                    Pipe fabrication is the process of welding piping components such as pipes, elbows, tees, flanges, etc., into engineered piping systems in exact accordance with our customers' design requirements. 
                                </p>
                            </div>
                        </div>


                        <div className="card-home">
                            <div className="card-img-home">
                                <img src={Card2} alt="ArchitecturalImage" />
                            </div>
                            <div className="card-text-home">
                                <h4 className="card-heading-home">Instrumentation Installation works</h4>
                                <p className="card-body-home">
                                    An instrument is a device that measures or manipulates process physical variables such as flow, temperature, level, or pressure etc. Instruments include many varied contrivances like transmitters and  analyzers. 
                                </p>
                            </div>
                        </div>


                        <div className="card-home">
                            <div className="card-img-home">
                                <img src={Card3} alt="ManagementImage" />
                            </div>
                            <div className="card-text-home">
                                <h4 className="card-heading-home">Construction Management & Labour Suppliers</h4>
                                <p className="card-body-home">

                                    Labour management in building construction means controlling the manpower problems, improving labour productivity and reducing time and cost overrun of projects. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-us">
                <div className="container">
                    <div className="grid-container">

                        <div className="left-col"></div>

                        <div className="right-col">
                            <h3 className="about-heading">About</h3>
                            <p className="about-body">
                                To continue building & developing a long term diversified. Safety conscious construction contractor, that provides quality product with pride, improves & challenges its employees & contribute to Community & environment by incorporating New technology.

                            </p>

                            <div className="details flex-container">
                                <div className="contact-id">
                                    <h5>Contact Us</h5>
                                    <p>+91- 9877374720, +91-7338499951<br />+91- 6381619809, +91-8009031155 </p>
                                    <p>dyadav7338@gmail.com <br /> dksingh@gmail.com</p>
                                </div>
                                <div className="location">
                                    <h5>Location</h5>
                                    <p>Champaklal B Prajapati, Khumbharwad Kanpura, <br /> Vyara, tapi, Gujarat(India)<br /> 394650 </p>
                                </div>
                                <div className="business-hour">
                                    <h5>Business Hours</h5>
                                    <p>Mon-Fri (9:00 AM to 5:00 PM)</p>
                                    <p>Sat-Sun (Closed)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="achievements">
                <div className="container">
                    <div className="grid-container">
                        <div className="achievement">
                            <p className="flex-container">
                                <span>2018</span>
                                <span>Year</span>
                                <span>Established</span>
                            </p>
                        </div>
                        <div className="achievement">
                            <p className="flex-container">
                                <span>09</span>
                                <span>Projects</span>
                                <span>Completed</span>
                            </p>
                        </div>
                        <div className="achievement">
                            <p className="flex-container">
                                <span>12</span>
                                <span>Constructor</span>
                                <span>Points</span>
                            </p>
                        </div>
                        <div className="achievement">
                            <p className="flex-container">
                                <span>02</span>
                                <span>Awards</span>
                                <span>Won</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="projects">
                <h1 className="projects-heading">Our Completed Projects</h1>
                <div className="grid-container-table">
                    <table className='table-body'>
                        <tr>
                            <th>Year</th>
                            <th>Description</th>
                            <th>Value of Job</th>
                            <th>Status</th>
                        </tr>


                        <tr>
                            <td>2010-11</td>
                            <td>Fabrication and erection structure,piping ONGC DFCU Dahej, Bharuch, Gujrat. </td>
                            <td>700000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2017</td>
                            <td>Fabrication and Erection Sturucture Job, Ratan Roof projects Pune.</td>
                            <td>6000000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2018</td>
                            <td>Fabrication and Erection Structure job, L.N.T Power</td>
                            <td>2500000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2018</td>
                            <td>Scafolding men power supply, kabir Enterprises, Kanpur</td>
                            <td>3000000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2018</td>
                            <td>Fabrication and Erection structure job, Creavtive Projects, Pune</td>
                            <td>2500000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2021</td>
                            <td>Fabrication and erection pipings, work L&T Hydro  Reliance CBM project Shahdol MP</td>
                            <td>30000000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2021</td>
                            <td>Stack modification structural Reliance CBM Project Shahdol MP</td>
                            <td>1.25000000</td>
                            <td>Till Running</td>
                        </tr>
                        <tr>
                            <td>2021</td>
                            <td>Erection works Piping and structure work L&T Hydro HPCL, Visakhapatnam, A.P</td>
                            <td>3.6000000</td>
                            <td>Completed</td>
                        </tr>
                        <tr>
                            <td>2021</td>
                            <td>Erection Piping works LSTICK 1 IOCL paradeep Orissa</td>
                            <td>1.26000000</td>
                            <td>Till Running</td>
                        </tr>
                    </table>



                </div>
            </section>


            <section className="testimonials">

                <Customer />

            </section>
            <Footer />

        </div>
    )
}

export default Home;
