import React, { useState } from 'react';
import '../../Home/Home.css';
import logo from '../../Assets/images/logo2.png'
import Modal from '../Modal/Modal';
import { Link } from 'react-router-dom';

function Footer() {
  // Modal status
  const [isOpen, SetIsOpen] = useState(false)

  // Close Modal function
  const closeModal = () => SetIsOpen(false)

  return (
    <div className="footer">
      <div className="container">
        <div className="grid-container">
          <div className="footer-item">
            <img src={logo} alt="footerLogo" />


          </div>
          <div className="footer-item">
            <h3 className="head">Company</h3>
            <ul className="list">

              <li>
              <Link to='/about' className='ourfounder-footer' > Our Founders </Link>
                </li>

              <li onClick={() => { SetIsOpen(true) }} >
                Manpower  Resource
              </li>
              <Modal
                open={isOpen}
                close={closeModal}
                headerBgColor={"blue"}
                title={"Our Manpower Resource"}
                titleColor={"white"}
                titleFontSize={"30px"}
                bgColor={"gray"}
                fade={true}
                backdrop={true}
                fullscreen={"true"}
                size={"lg"}
                centered={false}
                scrollable={true}
                transition={true}
              >
                <p className='para'>


                <table>
  <tr>
    <th>Serial Number </th>
    <th>Description</th>
    <th>Quantity</th>
  </tr>
  <tr>
    <td>01</td>
    <td>Engineer</td>
    <td>10</td>
  </tr>
  <tr>
    <td>02</td>
    <td>Supervisor</td>
    <td>22</td>
  </tr>
  <tr>
    <td>03</td>
    <td>Electrician </td>
    <td>06</td>
  </tr>
  <tr>
  <td>04</td>
    <td>Safety Supervisor</td>
    
    <td>06</td>
  </tr>
  <tr>
    <td>05</td>
    <td>Quality Engineer</td>
    <td>06</td>
  </tr>
  <tr>
    <td>06</td>
    <td>Fitter</td>
    <td>44</td>
  </tr>
  <tr>
    <td>07</td>
    <td>Welder Arc</td>
    <td>25</td>
  </tr>
  <tr>
    <td>08</td>
    <td>Welder TIG</td>
    <td>18</td>
  </tr>
  <tr>
    <td>09</td>
    <td>IBR Welder</td>
    <td>08</td>
  </tr>
  <tr>
  <td>10</td>
    <td>Grinding Man</td>
    
    <td>48</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Gas Cutter</td>
    <td>10</td>
  </tr>
  <tr>
    <td>12</td>
    <td>Rigger</td>
    <td>108</td>
  </tr>
  <tr>
    <td>13</td>
    <td>Khalasi</td>
    <td>70</td>
  </tr>
  <tr>
    <td>14</td>
    <td>Store Keeper</td>
    <td>10</td>
  </tr>
  <tr>
    <td>15</td>
    <td>Helper </td>
    <td>60</td>
  </tr>
</table>
                  
                  
                  </p>
              </Modal>

            </ul>
          </div>

          <div className="footer-item">
            <h3 className="head">Settings</h3>
            <ul className="list">
              <li>Terms & Conditions</li>
              <li>
              <Link to='/contact' id='feedback' className='feedback-footer' > Feedback </Link>
                
                </li>
            </ul>
          </div>
          <div className="footer-item">
            <h3 className="head">Contact Us</h3>
            <ul className="list">
              <li>LinkedIn</li>
              <li>Facebook</li>
              <li>Instagram</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;