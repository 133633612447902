import React from 'react'
import './AboutUs.css';
import Header from '../Core/Header/Header';
import Footer from '../Core/Footer/Footer';
import PriseImg from '../Assets/images/prise.jpg'
import SiteImg from '../Assets/images/site.png'
import DevendraImg from '../Assets/images/devendra.png'
import DhanajayImg from '../Assets/images/dhanajay.png'

function AboutUs() {
  return (
    <div>
      <Header />
      <div className="aboutus">
        
        <div className="about-text">About Us</div>
     

    </div>
      <div>
        <div className="main">
          <div className="form-wrap">
            <div className='ddsy-intro'>
              M/s. D.D.S.Y  Construction is a leading contractor in the field of  Piping, Structure & Equipment, Fabrication & Erection in India. We incorporate new technology & deliver value through innovation & skills. We benchmark our best practies, pay attention to lessons learned and constantly challenge ourseles to do better. From material & construction methods to management techniques and information systems, we always seek new and better ways to help our customers achieve their goals.
              Developing world-className capabilities for toaday’s toughest engineering and logistical challenges. Sophisticated tools & Process for precise planning, tracking & control.
            </div>
          </div>
          <div className="top">
            <img src={PriseImg} alt="PriceImage" />
          </div>
        </div>

        <div className="main">
          <div className="top">
            <img src={SiteImg} alt="SiteImage" />
          </div>
          <div className="form-wrap">
            <div className='site-text'>
              A passion for construction that has fuelled our suiccess.
              Concern for safety and having the best resources available for the professional completing of our project is a paramount in the mangement’s oversight of the company progess. We beieve in safety. Our human resources department plays a major role in obtaining the best-qualified employees, training and looking out for their safetly by constant evaluation of our field operations and job sites. <br />
              We have completed lot of projects : <br />  <br />
              1. RELIANCE CBM PROJECT SHAHDOL M.P   <br />
              2. FCHCU VRMP HPCL VISAKHAPATNAM - 3  <br />
              3. U VRMP HPCL VISAKHAPATNAM
              HEAVY EXCHANGER AREA
              
            </div>
          </div>
        </div>
      </div>

      <div className='our-team'>
        <div className='our-team-header'>Our Team</div>
        <div className='our-team-body'>
          <div>
              <div className='devendra-img'>
                <img src={DevendraImg} alt="devendra yadav"  className='devendra-img-body'/>
              </div>
              <div className='founder'>
                Founder
                </div>
                <div className='founder-name'>
                Mr. DEVENDRA KUMAR YADAV
                </div>
             
          </div>
          <div>
          <div className='dhanajay-img'> 
                <img src={DhanajayImg} alt="dhananjay singh" className='dhanajay-img-body'/>
              </div>
              <div className='co-founder'>
                  Co - Founder
                </div>
              Mr. DHANANJAY SINGH
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs