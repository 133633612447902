export const Data = [
    {
        question: 'What is your company location?',
        answer: 'Champaklal B Prajapati, Khumbharwad Kanpura, Vyara, tapi, Gujarat(India) 394650'
    },
    {
        question: 'How can I find your works demo?',
        answer: 'You have to come on our working site or you can also see here or you can connect with us on call or mail.'
    },
    {
        question: 'How many team members like enginners ,workers  etc in your company?',
        answer: 'I have all types of workers and machines related to construction work. if you want in number then it is almost 451.'
    },
    {
        question: 'How may projects your company completed yet?',
        answer: 'I have completed almost 9 projects and currently it is going on some projects.'
    },
    {
        question: 'How many construction resources do you have?',
        answer: 'I have all types of construction resorces like Air Cooled,Grinders, Chain Blocks,Electrical Boards,Sillings ,Welding  etc.'
    }
];