import React, {useRef} from 'react'
import './ContactUs.css'
import Header from '../Core/Header/Header'
import Footer from '../Core/Footer/Footer'
import emailjs from '@emailjs/browser';


function ContactUs() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_rrjuayo', 'template_dnl0c6i', form.current, 'CFO0i50pcBzjW0T8Q')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

 



  return (
    <div>
      <Header />
      <div className="contact">
        <div className="contact-braces"></div>
        <div className="contact-text">Contact Us</div>
        <div className="contact-braces"></div>

    </div>
      
      <div className="main">
        <div className="top">
          {/* <Location /> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.999984698043!2d73.39705571476485!3d21.112566785953018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be07f5ba6a0d4a3%3A0x622619b8d093541f!2sChapaneria%20Prajapati%20Samaj%20Ni%20Vadi!5e0!3m2!1sen!2sus!4v1662155486512!5m2!1sen!2sus" height="100%" width="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no"></iframe>
        </div>
        <div className="form-wrap">
          <form
          ref={form} onSubmit={sendEmail}
         
          >
            <h3>
              Get in touch with us
            </h3>
            <div className="input-container fname">
              <label for="fname">Name</label>
              <input id="fname" name="name" type="text" placeholder="Name" />
            </div>

            <div className="input-container email">
              <label for="email">Email</label>
              <input id="email" name="user_email" type="email" placeholder="E-mail" />
            </div>

            <div className="input-container-cnumber">
              <label for="cnumber">Contact Number</label>
              <input id="cnumber" name="contact_number" type="text" placeholder="Contact Number" />
            </div>

            <div className="input-container query">
              <label for="query">Query</label>
              <select required name="query" id="query">
                <option value="">Select your query</option>
                <option value="technical query">technical query</option>
                <option value="financial query">financial quer</option>
                <option value="financial query" id="getsupport">Get support</option>
                <option value="other">other</option>
              </select>
            </div>

            <div className="input-container message">
              <label for="message">Message</label>
              <textarea id="message" name="message"></textarea>
            </div>

            <button className="signup-btn" type="submit"  onClick={() => window.location.reload(false)}>Submit</button>

          </form>

        </div>


      </div>
      <div className="services">
        <div className='offers-details'>
          For More Offers & Support, Just ping this contact Number or Gmail
          <div>
        <p className='conatact-number'>  +91- 9877374720,    +91-7338499951,    +91- 6381619809,    +91-8009031155 </p>
        </div>
        <div>
        <p className='contact-gmail'>    dyadav7388@gmail.com,       dksingh@gmail.com</p>
        </div>
        </div>
        
      </div>
      <Footer />
    </div>

  )
}

export default ContactUs