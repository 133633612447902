

export const SidebarData = [
  {
    title: 'HOME',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'SERVICE',
    path: '/service',
    cName: 'nav-text'
  },
  {
    title: 'ABOUT US',
    path: '/about',
    cName: 'nav-text'
  },
  {
    title: 'CONTACT US',
    path: '/contact',
    cName: 'nav-text'
  }
];