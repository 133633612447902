import React, {useState, useRef, useEffect} from 'react'
import LeftArrow from '../../Assets/images/left-arrow-ic.svg'
import RightArrow from '../../Assets/images/right-arrow-ic.svg'
import {featuredProducts} from './FeaturedProducts'




let count = 0;
let slideInterval;
function Customer() {

    const [currentIndex, setCurrentIndex] = useState(0);

  
  const slideRef = useRef();

  const removeAnimation = () => {
    slideRef.current.classNameList.remove('fade-anim');
  };

  useEffect(() => {
    slideRef.current.addEventListener('animationend', removeAnimation);
    slideRef.current.addEventListener('mouseenter', pauseSlider);
    slideRef.current.addEventListener('mouseleave', startSlider);

    startSlider();
    return () => {
      pauseSlider();
    };
    // eslint-disable-next-line
  }, []);

  const startSlider = () => {
    slideInterval = setInterval(() => {
      handleOnNextClick();
    }, 30000);
  };

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  const handleOnNextClick = () => {
    count = (count + 1) % featuredProducts?.length;
    setCurrentIndex(count);
    // slideRef.current.classNameList.add("fade-anim");
  };
  const handleOnPrevClick = () => {
    const productsLength = featuredProducts?.length;
    count = (currentIndex + productsLength - 1) % productsLength;
    setCurrentIndex(count);
    // slideRef.current.classNameList.add("fade-anim");
  };


  return (
    <div>
         
                <h1 className="testimonials-heading">What Our <span>Team</span> Say?</h1>
                <div className="container"  ref={slideRef}>
                    <img
                        src={LeftArrow}
                        alt="Next"
                        className="testimonial-nav prev-item"
                        onClick={handleOnPrevClick}
                    />
                    <div className="testimonial active">
                        <p className="testimonial-body">
                            <span className="quote quote-1">"</span> 
                             {featuredProducts[currentIndex]?.body} 
                            <span className="quote quote-2">"</span>
                        </p>
                        <p className="testimonial-name">
                        👉 {featuredProducts[currentIndex]?.title}
                        </p>
                    </div>
                    
                    <div className="testimonial">
                        <p className="testimonial-body">
                            <span className="quote quote-1">"</span> Lorem ipsum dolor, sit amet
                            consectetur adipisicing elit. Laborum voluptate commodi delectus
                            cupiditate ducimus veritatis! Lorem ipsum, dolor sit amet
                            consectetur adipisicing elit. Alias, voluptate. Lorem ipsum dolor
                            sit amet consectetur adipisicing elit. Pariatur, a!
                            <span className="quote quote-2">"</span>
                        </p>
                        <p className="testimonial-name">Mr. 123</p>
                    </div>
                    <img
                        src={RightArrow}
                        alt="Next"
                        className="testimonial-nav next-item"
                        onClick={handleOnNextClick}
                    />
                </div>

            
    </div>
  )
}

export default Customer