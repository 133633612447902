import './App.css';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs'
import Service from './Components/Service/Service';
import ContactUs from './Components/ContactUs/ContactUs';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
    <Routes>
      
        <Route path='/' element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/service" element={<Service />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
