export const featuredProducts = [
    {
        "title": "Mr. DEVENDRA KUMAR YADAV",
        "body": "Happy customers are your biggest advocates and can become your most successful sales team."
      },
      {
        "title": " Mr. DHANANJAY SINGH",
        "body": "I believe in the power of recognition and empowerment leading to great employee engagement."
      },
      {
        "title": "Mr. Brijesh Yadav",
        "body": "A brand for a company is like a reputation for a person. You earn reputation by trying to do hard things well."
      },

 ]