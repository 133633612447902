import React from 'react'
import '../../Home/Home.css';
import Logo from '../../Assets/images/logo2.png';
import Navbar from '../Navbar/Navbar';

import { Link } from 'react-router-dom';

function Header() {
  return (
        <div className="header">
      <div className="container">
        <div className="flex-container nav-container">
          <div className="logo-container">
            <img src={Logo} alt="Logo" className="logo" />
          </div>

         
          <nav className="navbar-header">
            <ul className="nav-items-header flex-container">
              <li>
                <Link to="/"   className="nav-links">Home </Link>
              </li>
              <li>
                <Link to="/service"  className="nav-links">Services</Link>
              </li>
              <li>
                <Link to="/about"  className="nav-links">About Us</Link>
              </li>
              <li>
                <Link to="/contact"   className="nav-links">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <Navbar />
        </div>
      </div>
    
    </div>
  )
}

export default Header