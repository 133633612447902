import React from 'react'
import './Service.css'
import Header from '../Core/Header/Header'
import Footer from '../Core/Footer/Footer'
import Accordion from '../Core/Accordian/Accordian'

import Piping from '../Assets/images/piping.jpeg'
import Steel from '../Assets/images/steel.jpeg'
import Electrical from '../Assets/images/electrical.jpeg'
import Boiler from '../Assets/images/boiler.jpeg'
import Turbine from '../Assets/images/turbine.jpeg'
import Generator from '../Assets/images/generator1.jpeg'
import MisActivities1 from '../Assets/images/2nd.jpeg'
import Fabrication from '../Assets/images/Fabrication.jpg'


function Service() {
  return (
    <div>
      <div>
        <Header />
      </div>

      <section className="hero">
        <div className="flex">
          <h1 className="heading">
            Affordable Quality Services For Any Type Of Construction
          </h1>
          <div className="scroll-button"></div>
        </div>
      </section>

      <section className="features">
        <h1 className="heading">Our Features</h1>
        <div className="features-background">
          <div className="service-container">
            <div className="feature-container grid">

              <div className="feature-card">
                <div className="card-img">
                  <img src={Fabrication} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Fabrication & Erection Works</h3>
                  <p className="card-text">
                    Fabrication is the process of preparing components ready for assembly on site. Erection is the process of assembling fabricated components on site, and with careful planning can be completed relatively quickly.
                  </p>
                </div>
              </div>

              <div className="feature-card">
                <div className="card-img">
                  <img src={Piping} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Piping Works</h3>
                  <p className="card-text">
                    Within industry, piping is a system of pipes used to convey fluids (liquids and gases) from one location to another. The engineering discipline of piping design studies the efficient transport of fluid.
                  </p>
                </div>
              </div>


              <div className="feature-card">
                <div className="card-img">
                  <img src={Steel} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Steel Structural</h3>
                  <p className="card-text">
                    Structural steel is a category of steel used for making construction materials in a variety of shapes. Many structural steel shapes take the form of an elongated beam having a profile of a specific cross section.
                  </p>
                </div>
              </div>


              <div className="feature-card">
                <div className="card-img">
                  <img src={Turbine} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Turbine</h3>
                  <p className="card-text">
                    A turbine is a rotary mechanical device that extracts energy from a fluid flow and converts it into useful work. The work produced by a turbine can be used for generating electrical power when combined with a generator.
                  </p>
                </div>
              </div>




              <div className="feature-card">
                <div className="card-img">
                  <img src={Boiler} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Boiler</h3>
                  <p className="card-text">
                    A boiler is a closed vessel in which fluid is heated. The fluid does not necessarily boil. The heated or vaporized fluid exits the boiler for use in various processes or heating applications, including water heating, central heating, boiler-based power generation, cooking, and sanitation
                  </p>
                </div>
              </div>



              <div className="feature-card">
                <div className="card-img">
                  <img src={Generator} alt='generator-img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Generator</h3>
                  <p className="card-text">
                    In electricity generation, a generator is a device that converts motive power into electric power for use in an external circuit. Sources of mechanical energy include steam turbines, gas turbines, water turbines, internal combustion engines, wind turbines and even hand cranks.
                  </p>
                </div>
              </div>
              <div className="feature-card">
                <div className="card-img">
                  <img src={Electrical} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Electrical works</h3>
                  <p className="card-text">

                    Electrical work means: connecting electricity supply wiring to electrical equipment or disconnecting electricity supply wiring from electrical equipment; or manufacturing, constructing, installing, removing, adding, testing, replacing, repairing, altering or maintaining electrical equipment or an electrical.
                  </p>
                </div>
              </div>

              <div className="feature-card">
                <div className="card-img">
                  <img src={MisActivities1} alt='img' />
                </div>
                <div className="card-body">
                  <h3 className="card-head">Misc Activities </h3>
                  <p className="card-text">
                    Miscellaneous construction elements means a variety of con- struction elements or activities such as, but not limited to: Reser- voir linings; parapet walls or low berms for wave containment; minor reconstruction of isolated portions of the impounding barrier; inter- nal drainage improvements; and erosion protection.
                  </p>
                </div>
              </div>

            </div>

            <div className="flex">
              <button className="view-btn btn">View More</button>
            </div>
          </div>
        </div>
      </section>

      <section className="ribbon">
        <div className="container">
          <div className="ribbon-container flex">
            <p>How we Works</p>
            <span></span>
            <p>Which types of  Our services</p>
            <span></span>
            <p>Discuss with us</p>
          </div>
        </div>
      </section>

      <section className="accordians">
        <div className="container">
          <h1 className="accordion-heading">Quick Tips and Topics</h1>
          <div className="accordian-container">
            {/* <div className="accordian">
              <div>
                <img src={AddIcon} alt="Show More" className="expand-ic" />
              </div>
            </div>
            <div className="accordian">
              <div>
                <img src={AddIcon} alt="Show More" className="expand-ic" />
              </div>
            </div>
            <div className="accordian">
              <div>
                <img src={AddIcon} alt="Show More" className="expand-ic" />
              </div>
            </div>
            <div className="accordian">
              <div>
                <img src={AddIcon} alt="Show More" className="expand-ic" />
              </div>
            </div>
            <div className="accordian">
              <div>~
                <img src={AddIcon} alt="Show More" className="expand-ic" />
              </div>
            </div> */}
            <Accordion />
          </div>
        </div>
      </section>

      <section className="contact-service">
        <div className="container-service">
          <h1 className="heading-service">You want to see our action ?</h1>
          <p className="contact-text-service">

          </p>
          <button className="call-us-btn btn">
            <a href="tel:9877374720">
              Call Us
            </a>
          </button>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Service;